import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagementFileRepository } from '@domain/services/management-file/repository/management-file.repository';
import { Observable } from 'rxjs';
import { URL_BACKEND } from 'src/app/core/config/url';

@Injectable({
  providedIn: 'root',
})
export class ManagementFileService extends ManagementFileRepository {
  private urlApiPrivate = `${URL_BACKEND}/management/file/private`;

  constructor(private http: HttpClient) {
    super();
  }

  override downloadPrivateFile(path: string): Observable<any> {
    return this.http.get<any>(`${this.urlApiPrivate}/download?path=${path}`, {
      responseType: 'blob' as 'json',
    });
  }
}